import { FC, useState } from "react";
import { Outlet, Route, useLocation } from "react-router-dom";
import { track } from "segment";
import { Guide, GuideButton, LiveIndicator } from "taskpane/components";

import { Portfolios, Products } from "./views";

import styles from "./LiveCurves.module.scss";

/**
 * When the user clicks on the Guide button of the ‘Add Portfolio’ or the ‘Add Products’ screens.
 * @param screen Active screen
 */
const trackGuideClicked = (screen: "Add Portfolio" | "Add Products"): void => {
  track("Guide Clicked", { screen });
};

const Component: FC = () => {
  const [guide, setGuide] = useState<"portfolios" | "products">();

  const { pathname } = useLocation();

  const onClickGuideButton = () => {
    const { length, [length - 1]: screen } = pathname.split("/");

    const defaultTab = ["portfolios", "products"].includes(screen) ? (screen as typeof guide) : "portfolios";
    trackGuideClicked(defaultTab === "portfolios" ? "Add Portfolio" : "Add Products");
    setGuide(defaultTab);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img className={styles.logo} alt="logo" src="/assets/logoHorizontal-64.png" />
        <div className={styles.right}>
          <GuideButton onClick={onClickGuideButton} />
          <LiveIndicator />
        </div>
      </div>
      <Outlet />
      {guide && <Guide defaultTab={guide} onBack={() => setGuide(undefined)} />}
    </div>
  );
};

const Router = (
  <>
    <Route path="portfolios" element={<Portfolios />} />
    <Route path="products" element={<Products />} />
  </>
);

export const LiveCurves = {
  Component,
  Router,
};
