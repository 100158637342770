import { useEffect, useMemo, useState } from "react";
import { AxiosError } from "axios";
import { getProducts } from "services";
import type { Category, Group, Product, ProductsDTO, Provider } from "services/liveCurves/types";
import { useFeatureFlag } from "taskpane/hooks/useFeatureFlag";
import { filterByText, sortBy } from "utils/helpers";

import {
  ExceptionNetworkError,
  ExceptionPortfolioBackendError,
  ExceptionPortfolioErrorType,
} from "../../Portfolios/utils";

export type Filter = { value: string | undefined; order: number; title: string };
export type Filters = Record<string, Filter>;

type ProductGroupMapped = Omit<Group, "tabAllVisible"> & {
  products: Product[];
  origins: string[];
  showAllOrigins: boolean;
  total: number | undefined;
};

export type CategoryMapped = Omit<Category, "productGroups"> & {
  productGroups: ProductGroupMapped[];
  total: number | undefined;
};

type ProductsMapped = {
  categories: CategoryMapped[];
  products: Product[];
};

const mapper = (data: ProductsDTO): ProductsMapped => ({
  categories: sortBy(data.categories, "order").flatMap((category) =>
    category.productGroups.length > 0
      ? {
          ...category,
          productGroups: category.productGroups.map(({ tabAllVisible = false, ...group }): ProductGroupMapped => {
            const products = sortBy(
              data.products.filter((product) => product.productGroups.some((pg) => pg.id === group.id)),
              "shortName"
            ).map((product) => ({
              ...product,
              unit: product.unit === null || product.unit.length === 0 ? null : product.unit,
            }));

            const origins = [
              ...new Set(
                products.flatMap((product) =>
                  Array.isArray(product.origins) && product.origins.length > 0 ? product.origins : product.origin
                )
              ),
            ]
              .filter(Boolean)
              .sort((a, b) => a.localeCompare(b));

            const showAllOrigins = origins.length > 0 && tabAllVisible;

            return {
              ...group,
              origins,
              showAllOrigins,
              products,
              total: undefined,
            };
          }),
          total: undefined,
        }
      : []
  ),
  products: data.products,
});

export const getDataProviderFromCategory = (category: CategoryMapped) => {
  return Array.from(
    new Set(
      category.productGroups.flatMap((group) =>
        group.products.flatMap((product) => (typeof product.dataProvider === "string" ? product.dataProvider : []))
      )
    )
  );
};

type PartialRecord<K extends string | number | symbol, T> = Partial<Record<K, T>>;

export const useProductsData = () => {
  const [filterText, setFilterText] = useState("");
  const [filtersState, setFiltersState] = useState<PartialRecord<string, Filters>>({});

  const [dataProvider, setDataProvider] = useState<Provider>("DP_2");
  const [providers, setProvider] = useState<PartialRecord<NonNullable<Provider>, Provider[]>>({});

  const [data, setData] = useState<ProductsMapped | undefined>(undefined);
  const [dataError, setDataError] = useState<ExceptionPortfolioErrorType>();

  const isOBInExcel = !!useFeatureFlag("fe_excel_ob");

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getProducts(isOBInExcel);

        if (!result.categories || !result.products) throw new AxiosError();

        const mappedData = mapper(result);
        setData(mappedData);
        setProvider(
          mappedData.categories.reduce(
            (prev, current) => ({ ...prev, [current.name]: getDataProviderFromCategory(current) }),
            {}
          )
        );
      } catch (e) {
        if (e instanceof AxiosError) {
          if (e.code === "ERR_NETWORK") {
            return setDataError(ExceptionNetworkError());
          } else {
            setDataError(ExceptionPortfolioBackendError());
          }
        }
        setData(undefined);
      }
    };

    getData();

    const interval = setInterval(getData, 60000);

    return () => clearInterval(interval);
  }, [isOBInExcel]);

  const filteredData: ProductsMapped | null = useMemo(() => {
    if (!data) return null;

    return {
      ...data,
      categories: data?.categories.flatMap((category) => {
        const providers = getDataProviderFromCategory(category);

        const filterByProvider = (product: Product) => {
          if (isOBInExcel && providers && providers.length > 1) {
            return !(typeof product.dataProvider === "string" && product.dataProvider !== dataProvider);
          } else {
            return true;
          }
        };

        const filterByLabel = (product: Product) => filterByText(`${product.shortName} (${product.unit})`, filterText);

        const groups = category.productGroups.flatMap((group) => {
          const products = group.products.filter(filterByProvider).filter(filterByLabel);

          const check = filterByText(group.name, filterText) || products.length;
          return check
            ? {
                ...group,
                products: products.length ? products : group.products,
                total: products.length || undefined,
              }
            : [];
        });

        const check = filterByText(category.name, filterText) || !!groups.length;

        return check
          ? {
              ...category,
              productGroups: groups.length ? groups : category.productGroups,
              total: groups.reduce((prev, curr) => {
                const total = typeof curr.total === "number" ? curr.total : 0;

                return prev + total;
              }, 0),
            }
          : [];
      }),
    };
  }, [data, filterText, dataProvider]);

  const filterError = !!filterText.length && !filteredData?.categories?.length;

  const updateFilters = (filtersKey: string, filters: Filters) => {
    setFiltersState((prev) => ({ ...prev, [filtersKey]: filters }));
  };

  const updateFilterText = (newFilterText: string) => {
    setFilterText(newFilterText);
    setFiltersState({});
  };

  return {
    data: filteredData,
    dataError,
    dataProvider,
    filterError,
    filterText,
    filtersState,
    providers,
    setDataProvider,
    updateFilterText,
    updateFilters,
  };
};
