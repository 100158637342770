import { FC } from "react";
import { CircleFillIcon, PauseIcon } from "@fluentui/react-icons-mdl2";
import clsx from "clsx";

import { useLiveIndicator } from "./useLiveIndicator";

import styles from "./LiveIndicator.module.scss";

type Props = {
  className?: string;
};

const Icons = {
  connected: { label: "Live", Icon: CircleFillIcon },
  connecting: { label: "Connecting", Icon: CircleFillIcon },
  disconnected: { label: "Not Connected", Icon: PauseIcon },
};

export const LiveIndicator: FC<Props> = ({ className }) => {
  const status = useLiveIndicator();

  const { label, Icon } = Icons[status];

  return (
    <div className={clsx(styles.root, className)}>
      <div className={clsx(styles.content, styles[status])}>
        <Icon className={styles.icon} />
        <span className={styles.label}>{label}</span>
      </div>
    </div>
  );
};
