import type { LaunchDarklyMap } from "types";
import { getFeatureFlagValue } from "utils/user";

import useTaskPaneContext from "../context";

export const useFeatureFlag = <FeatureFlag extends keyof LaunchDarklyMap>(
  flag: FeatureFlag
): LaunchDarklyMap[FeatureFlag] | undefined => {
  const { user } = useTaskPaneContext();

  return getFeatureFlagValue(user, flag);
};
