import { FC } from "react";
import { Navigate } from "react-router";
import { Entitlements } from "services/auth/types";
import useTaskPaneContext from "taskpane/context";
import { isEntitlementEnabled } from "utils/user";

type Props = {
  Component: React.ComponentType;
  entitlement: Entitlements;
};

export const ProtectedView: FC<Props> = ({ Component, entitlement }) => {
  const { user } = useTaskPaneContext();

  if (!user) return <div />;

  if (!isEntitlementEnabled(user, entitlement)) return <Navigate to="/" replace />;

  return <Component />;
};
