import { useEffect, useState } from "react";

import { useFeatureFlag } from "../../hooks/useFeatureFlag";

export const useLiveIndicator = () => {
  const isNewWSEnabled = !!useFeatureFlag("excel_new_ws");

  const [legacyStatus, setLegacyStatus] = useState(global.Sparta.getSocketStatus());
  const [newStatus, setNewStatus] = useState(global.CurvesDataFeed.liveStatus.getValue());

  useEffect(() => {
    const interval = setInterval(() => {
      setLegacyStatus(global.Sparta.getSocketStatus());
    }, 2000);

    const liveStatusSubscription = global.CurvesDataFeed.liveStatus.subscribe(({ value }) => {
      setNewStatus(value);
    });

    return () => {
      clearInterval(interval);
      liveStatusSubscription.unsubscribe();
    };
  }, []);

  return isNewWSEnabled ? newStatus : legacyStatus;
};
